import React from "react"
import { StaticQuery, graphql } from "gatsby"

import {
  Button,
  Section,
  HighlightedLink,
  HighlightedLinkStrong,
  LargeCardContainer,
  SEO,
  Box,
} from "components"

import StandardTemplate from "templates/StandardTemplate"
import Posts from "components/Posts"

const INDEX_PAGE_QUERY = graphql`
  query HomePageQuery {
    allPressLinksJson {
      edges {
        node {
          title
          publicationName
          link
        }
      }
    }
  }
`

const IndexPage = () => (
  <StaticQuery
    query={INDEX_PAGE_QUERY}
    render={data => {
      const pressItems = data.allPressLinksJson.edges

      return (
        <StandardTemplate>
          <SEO title="Home" />
          <Box mb={6} maxWidth={"840px"}>
            <h1>Hey, I'm Luke.</h1>
            <p>
              I love making products that help people. Currently, I'm working on
              films and tools for making them at<span> </span>
              <HighlightedLinkStrong href="https://sequence.film">
                Sequence
              </HighlightedLinkStrong>
              .
            </p>

            <p>
              Previously, I was connecting the world through Community WiFi at<span> </span>
              <HighlightedLinkStrong target="_blank">
                Viasat
              </HighlightedLinkStrong>
              . I've been a student of Benedictine monks at<span> </span>
              <HighlightedLinkStrong href="https://www.priory.org">
                Saint Louis Priory School
              </HighlightedLinkStrong>
              , and a student of Jimmy Iovine/Dr. Dre at the<span> </span>
              <HighlightedLinkStrong href="https://iovine-young.usc.edu">
                University of Southern California
              </HighlightedLinkStrong>
              .
            </p>
            <Button
              onClick={() => {
                window.location.href =
                  "mailto:lucasmcgartland@gmail.com?subject=Hi"
              }}
            >
              Say hello
            </Button>
          </Box>
          <Section title={"Latest Writing"}>
            <LargeCardContainer>
              <Posts />
            </LargeCardContainer>
          </Section>

          <Section title={"In the press"}>
            <ul style={{ marginBottom: 0 }}>
              {pressItems.map((node, index) => {
                return (
                  <li style={{ marginBottom: 0 }} key={"index" + index}>
                    <HighlightedLink
                      href={node.node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {node.node.title} — {node.node.publicationName}
                    </HighlightedLink>
                  </li>
                )
              })}
            </ul>
          </Section>
        </StandardTemplate>
      )
    }}
  />
)

export default IndexPage
